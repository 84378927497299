.carousel-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
}

.carousel {
    display: flex;
    gap: 20px;
    justify-content: space-around;
    overflow: hidden;
}

.carousel-item {
    border-radius: 3px;
    background-color: rgba(255,255,255, 0.5);
    flex-shrink: 0;
    width: 200px;
    text-align: center;
    min-height: 200px;
}

.carousel-item img {
    margin-top: 5px;
    margin-right: auto;
    margin-left: auto;
    display: block;
    width: 100px;
    height: 100px;
    object-fit: contain; /* Для корректного отображения изображений */
    border-radius: 20%; /* Закругление углов для создания круглых изображений */
}

.caption {
    text-align: center;
    margin-top: 15px;
    font-size: 18px;
    margin-left: 0;
    padding: 0 5px 0 5px;
    color: #282c34;
    font-weight: 600;
}

/* Дополнительные стили для адаптации */
@media (max-width: 768px) {
    .carousel-item {
        width: 130px;
    }
}

@media (max-width: 992px) {
    .carousel-item {
        width: 140px;
    }
}
.prev-button,
.next-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 40px;
    color: #000;
    opacity: 0.7;
    transition: opacity 0.3s ease;
}

.prev-button:hover,
.next-button:hover {
    opacity: 1;
    transform: scale(1.2); /* Увеличение размера на 20% при наведении */
}
