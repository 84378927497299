.section1 {
    justify-content: flex-start !important;
    flex-direction: column;
}
.section2{
    display: flex;
    flex-direction: column;
    gap: 130px;
}
.section3{
    display: flex;
    flex-direction: column;
    justify-content: space-around!important;

}

.section3 h2{
    font-size: calc((100vw - 320px)/(1920 - 320) * (50 - 23) + 23px);
    margin-bottom: 250px;
}
.contact-block{
    display: flex;
    gap: 130px;
    margin-bottom: 100px;
}
.contact-card{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    width: 350px;
    height: 300px;
}
@media screen and (max-width: 800px){
    .contact-card{
        width: 300px;

    }
}
.contact-card .background{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: #f1eaea;
    opacity: 0.5;
}
.contact-card p{
    padding-left: 10px;
    padding-right: 10px;
    z-index: 2;
    color: #1d2026;
    margin:  auto 0;
}
.items1 h2{
    font-size: calc((100vw - 320px)/(1920 - 320) * (50 - 23) + 23px);

}

.items2 h2{
    font-size: calc((100vw - 320px)/(1920 - 320) * (50 - 23) + 23px);

}
.items1{
    text-align: center;
}
.items2{
    text-align: center;
    margin-bottom: 100px;
}
/*noinspection CssNoGenericFontName*/

h1 {
    color: antiquewhite;
    align-self: flex-start;
    font-size: calc((100vw - 320px)/(1920 - 320) * (130 - 33) + 33px);

    margin-left: 150px;
    margin-top: 100px;
    font-weight: 400;
    font-family: "Droid Serif";
}

/*noinspection CssNoGenericFontName*/
p{
    font-family: "Droid Serif";
    align-self: flex-start;
    color: antiquewhite;
    font-weight: 200;
    max-width: 800px;
    margin-top: 50px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
    font-size: calc((100vw - 320px)/(1920 - 320) * (24 - 18) + 18px);

}
@media screen and  (min-width: 1440px){
    .contact-block{
        display: flex;
        gap: 250px;
        margin-bottom: 100px;
    }
    p{
        align-self: flex-start !important;
        margin-left: 150px;
        /*font-size: 26px;*/
        padding-left: 0;
    }
}
@media screen and (max-width: 778px) {
    .section2{
        gap: 10px;
    }
    .contact-block{
        display: flex;
        gap: 20px;
        margin-bottom: 100px;
    }
    h1 {
        white-space: nowrap;
        margin: 100px auto 0;
    }
    p{
        margin-top: 50px;
        margin-right: auto;
        margin-left: auto;
        padding-right: 15px;
        padding-left: 15px;
    }
}
@media screen and (max-width: 630px){
    .section3 h2{
        margin-bottom: 20px;
    }
    .contact-block{
        flex-direction: column;
        margin-bottom: 30px;
    }
}
@media screen and (max-width: 500px) {
    .section2{
        gap: 10px;
    }
    h1 {
        margin-top: 100px;
        margin-right: auto;
        margin-left: auto;
        white-space: nowrap;
    }

    p {
        margin-right: auto;
        margin-left: auto;
        padding-right: 15px;
        padding-left: 15px;
        word-break: break-word;
    }
}
